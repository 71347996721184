import mediaVendorService from "../../services/mediavendor";
const getDefaultState = () => {
    return {
        MediaVendor: {
            ID: 0,
            IsCreditCardAccepted: null,
            IsBankingInfoCollected: null,
            CreditCardFiles: [],
            IsDigitalMedia: null,
            IsMediaRepRequest: null,
            CallLetters: null,
            MarketNumbers: null,
            MarketName: null,
            VendorBusinessName: null,
            VendorInvoiceCopyFiles: [],
            IdentificationType: null,
            USTaxID: null,
            Foreign: null,
            SocialSecurityNumber: null,
            IdentificationFiles: [],
            RemittanceAdviceEmail: null,
            SampleMediaKitFiles: [],
            EmployeeTitle: null,
            EmployeePhoneNumber: null,
            EmployeeAgency: null,
            AgencyName: null,
            Attestation: null,
            UrlSpecifiedInForm: null,
            TypeOfProductOrServiceProvidedByVendor: null,
            DollarsAnticipatedToConductAnnuallyByVendor: null,
            VendorFormIncludeACHBankingInfo: null,
            AnyGiftOrCompensationOfferedToYouToPlaceOrder: null,
            CompensationReceived: null,
            EmployeeVendorRelationshipDetails: {
                FormerEmployeeName: null,
                FamilyMemberName: null,
                FriendName: null,
                VendorRelationshipWithAgencyClient: null,
                VendorOfFormerEmployee: null,
                VendorWasFormerEmployeeOfAgency: null,
                VendorHasRelationshipWithAnotherAgencyEmployee: null,
                Other: null,
                MediaVendorRequestID: 0,
                ID: 0,
            },
            EmployeeVendorRelationship: [],
            // MediaTypes: [],
            MediaType: null, //for normal select ion
            EmployeeRelationships: [],
            Requestor: null,
            IsEditDataLoaded: false,
            CompletedForms: [],
            Status: null,
            infofromname: null,
            infofromtitle: null,
            infofromphone: null,
            InfoFromSourceIsVerbal: false,
            InfoFromSourceIsWritten: false,
            InfoFromSourceVerbal: null,
            InfoFromSourceWritten: null,
            SetupInfoSource: [],
            ACHPayment: null,
            VendorRemittanceName: null,
            RemittanceCountry: null,
            StreetAddressLine1: null,
            StreetAddressLine2: null,
            RemittanceCity: null,
            RemittanceState: null,
            RemittanceZipCode: null,
            PrintValueSelected: null,
            BankAccountName: null,
            BankAccountNumber: null,
            BankRoutingNumber: null,
            BankName: null,
            BankCountry: null,
            BankAddressLine1: null,
            BankAddressLine2: null,
            BankCity: null,
            BankState: null,
            BankZipCode: null,
            RemittanceEmailAddress: null,
            CreditLimitation: null,
            AreThereConditionsOrLimitations: null,
            BankLetterInvoice: [],
            HasACHPaymentSetup: null,
            HasBankLetterInvoice: null,
            FormerEmployeeReimbursement: null,
            ClientCode: null,
            Entity: null,
            EntityName: null,
        },
    };
};

// initial state
const state = getDefaultState();

// getters
const getters = {
    MediaVendor: (state) => {
        return state.MediaVendor;
    },
};

// actions
const actions = {
    async GetFormData(st, id) {
        await mediaVendorService
            .GetFormData(id, st.rootState.auth.idToken)
            .then((res) => {
                st.commit("loadVendorData", res.data);
                st.commit("loadVendorFiles", res.data);

                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    GetDraftFormData(st, id) {
        return mediaVendorService.GetDraftFormData(id, st.rootState.auth.idToken);
    },
    GetRequestWithTaskDetails(st, id) {
        return mediaVendorService.GetRequestWithTaskDetails(
            id,
            st.rootState.auth.idToken
        );
    },
    AddRequest(st, data) {
        return mediaVendorService.AddRequest(data, st.rootState.auth.idToken);
    },
    AddRequestWithFiles(st, data) {
        return mediaVendorService.AddRequestWithFiles(
            data,
            st.rootState.auth.idToken
        );
    },
    ApproveRequestLevel1(st, data) {
        return mediaVendorService.ApproveRequestLevel1(
            data,
            st.rootState.auth.idToken
        );
    },
    ApproveRequestLevel2(st, data) {
        return mediaVendorService.ApproveRequestLevel2(
            data,
            st.rootState.auth.idToken
        );
    },
    AccountingApprove(st, data) {
        return mediaVendorService.AccountingApprove(
            data,
            st.rootState.auth.idToken
        );
    },
    CFOApprove(st, data) {
        return mediaVendorService.CFOApprove(
            data,
            st.rootState.auth.idToken
        );
    },
    CompleteVendorRequest(st, data) {
        return mediaVendorService.CompleteVendorRequest(
            data,
            st.rootState.auth.idToken
        );
    },
    RejectRequestLevel1(st, data) {
        return mediaVendorService.RejectRequest(
            data,
            st.rootState.auth.idToken,
            "RejectLevel1"
        );
    },
    RejectRequestLevel2(st, data) {
        return mediaVendorService.RejectRequest(
            data,
            st.rootState.auth.idToken,
            "RejectLevel2"
        );
    },
    RejectVendorValidation(st, data) {
        return mediaVendorService.RejectRequest(
            data,
            st.rootState.auth.idToken,
            "RejectVendorValidation"
        );
    },
    AccountingReject(st, data) {
        return mediaVendorService.RejectRequest(
            data,
            st.rootState.auth.idToken,
            "AccountingReject"
        );
    },
    CFOReject(st, data) {
        return mediaVendorService.RejectRequest(
            data,
            st.rootState.auth.idToken,
            "CFOReject"
        );
    },
    GetRequests(st, options) {
        return mediaVendorService.GetRequests(st.rootState.auth.idToken, options);
    },
    DownloadList(st, data) {
        return mediaVendorService.DownloadList(st.rootState.auth.idToken, data);
    },
    RenderUploadedFiles(st, { id, category }) {
        return mediaVendorService.RenderUploadedFiles(
            st.rootState.auth.idToken,
            id,
            category
        );
    },
    UpdateRequestWithFiles(st, { id, data }) {
        return mediaVendorService.UpdateRequestWithFiles(
            id,
            data,
            st.rootState.auth.idToken
        );
    },
    GetUsers(st, { agencyName, name }) {
        return mediaVendorService.GetUsers(
            st.rootState.auth.idToken,
            agencyName,
            name
        );
    },
    SaveAsDraft(st, data) {
        return mediaVendorService.SaveAsDraft(data, st.rootState.auth.idToken);
    },

    GetAuditFormData(st, id) {
        return mediaVendorService.GetAuditFormData(id, st.rootState.auth.idToken);
    },
    GetWorkflowStates(st, id) {
        return mediaVendorService.GetWorkflowStates(id, st.rootState.auth.idToken);
    },
    GetWorkflowHistoryStates(st, id) {
        return mediaVendorService.GetWorkflowHistoryStates(
            id,
            st.rootState.auth.idToken
        );
    },
    DownloadUploadTemplate(st, data) {
        return mediaVendorService.DownloadUploadTemplate(
            st.rootState.auth.idToken,
            data
        );
    },
    PendingVendorApproveorComplete(st, data) {
        return mediaVendorService.PendingVendorApproveorComplete(
            data,
            st.rootState.auth.idToken
        );
    },
};

function GetFilesCategory() {
    return [
        "SampleMediaKitFiles",
        "CreditCardFiles",
        "VendorInvoiceCopyFiles",
        "IdentificationFiles",
        "SetupInfoSource",
        "CompletedForms",
        "BankLetterInvoice",
    ];
}
// mutations
const mutations = {
    reset(st) {
        const s = getDefaultState();
        Object.keys(st).forEach((d) => {
            st[d] = s[d];
        });
    },
    loadVendorData(state, data) {
        Object.assign(state.MediaVendor, data);
        state.MediaVendor.Status = data.Status;
        state.MediaVendor.EmployeePhoneNumber = data.EmployeePhoneNumber;
        state.MediaVendor.Entity = data.Entity;
        state.MediaVendor.EmployeeAgency = data.EmployeeAgency;
        state.MediaVendor.AnyGiftOrCompensationOfferedToYouToPlaceOrder = data.AnyGiftOrCompensationOfferedToYouToPlaceOrder == true ? "true" : "false";
        state.MediaVendor.IsCreditCardAccepted = data.IsCreditCardAccepted == true ? "true" : "false";
        state.MediaVendor.IsMediaRepRequest = data.IsMediaRepRequest == true ? "true" : "false";
        state.MediaVendor.IsDigitalMedia = data.IsDigitalMedia == true ? "true" : "false";
        state.MediaVendor.UrlSpecifiedInForm = data.UrlSpecifiedInForm == true ? "true" : "false";
        state.MediaVendor.IsBankingInfoCollected = data.IsBankingInfoCollected == true ? "true" : "false";
        state.MediaVendor.ACHPayment = data.ACHPayment == true ? "true" : "false";
        state.MediaVendor.HasACHPaymentSetup = data.HasACHPaymentSetup == true ? "true" : "false";
        state.MediaVendor.HasBankLetterInvoice = data.HasBankLetterInvoice == true ? "true" : "false";
        state.MediaVendor.AreThereConditionsOrLimitations = data.AreThereConditionsOrLimitations == true ? "true" : "false";
        state.MediaVendor.FormerEmployeeReimbursement = data.FormerEmployeeReimbursement == true ? "true" : "false";
        state.MediaVendor.EmployeeVendorRelationship = [];
        if (data.EmployeeVendorRelationship != null) {
            data.EmployeeVendorRelationship.forEach((val) => {
                state.MediaVendor.EmployeeVendorRelationship.push(val.RelationshipName);
            });
        }
    },
    loadVendorFiles(state, data) {
        if (data.Files.length > 0) {
            GetFilesCategory().forEach((file) => {
                if (typeof file == "undefined" || file == null) {
                    return;
                } else {
                    let files = data.Files.filter((catFile) => {
                        return catFile.Category == file;
                    });
                    if (files.length > 0) {
                        files.forEach((originalFile) => {
                            state.MediaVendor[file].push(originalFile);
                        });
                    }
                }
            });
        }
        state.MediaVendor.IsEditDataLoaded = true;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};